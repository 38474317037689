'use client';

import 'dayjs/locale/en';
import 'dayjs/locale/he';
import merge from 'lodash/merge';
import { he as heAdapter, enUS as enUSAdapter } from 'date-fns/locale';

// core
import { heIL as heCore, enUS as enUSCore } from '@mui/material/locale';
// data-grid
// import { enUS as enUSDataGrid, heIL as heIlDataGrid, } from '@mui/x-data-grid';
// date-pickers
import { heIL as heDate, enUS as enUSDate } from '@mui/x-date-pickers/locales';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'עברית',
    value: 'he',
    systemValue: merge(heDate, heCore),
    adapterLocale: heAdapter,
    icon: 'flagpack:il',
    numberFormat: {
      code: 'he-IL',
      currency: 'ILS',
    },
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
    numberFormat: {
      code: 'en-US',
      currency: 'USD',
    },
  },
];

export const defaultLang = allLangs[0];

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
